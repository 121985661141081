<template>
	<div id="mian">
		<div class="desc">
        <h5 class="jsxx">基本信息</h5>
    </div>
		<div class="input_con">
			<ul class="inputBox">
				<li><span><i>*</i>第三方名称</span>
					<el-input class="inputs" v-model="formData.thirdName" maxlength="128" placeholder="输入第三方名称" tabindex="1"></el-input>
				</li>
				<li><span><i>*</i>第三方编号</span>
					<el-input class="inputs" v-model="formData.thirdNo" maxlength="128" placeholder="输入第三方编号" tabindex="2"></el-input>
				</li>
				<li><span><i>*</i>第三方APPID</span>
					<el-input class="inputs" v-model="formData.appId" maxlength="128" placeholder="输入第三方APPID" tabindex="3"></el-input>
				</li>
				<li><span><i>*</i>第三方联系号码</span>
					<el-input class="inputs" v-model="formData.componentPhone" maxlength="11" placeholder="输入第三方联系号码" tabindex="4"></el-input>
				</li>
				<li><span><i>*</i>服务器域名</span>
					<el-input class="inputs" v-model="formData.requestDomain" maxlength="128" placeholder="输入服务器域名" tabindex="5"></el-input>
				</li>
				<li><span><i>*</i>加解密key</span>
					<el-input class="inputs" v-model="formData.encodingAesKey" maxlength="128" placeholder="输入加解密key" tabindex="6"></el-input>
				</li>
				<li><span><i>*</i>验证token</span>
					<el-input class="inputs" v-model="formData.token" maxlength="128" placeholder="输入验证token" tabindex="7"></el-input>
				</li>
				<li><span><i>*</i>密钥</span>
					<el-input class="inputs" v-model="formData.appSecret" placeholder="输入密钥" tabindex="8"></el-input>
				</li>
				<li><span>第三方redirect_url</span>
					<el-input class="inputs" v-model="formData.thirdRedirectUrl" maxlength="128" placeholder="输入第三方redirect_url" tabindex="9"></el-input>
				</li>
				<li><span>备注</span>
					<el-input class="inputs" v-model="formData.remark" maxlength="128" placeholder="输入备注" tabindex="10"></el-input>
				</li>
				<!-- <li><span>授权url</span>
					<el-input class="inputs" v-model="warrantUrl"  placeholder="输入授权url" tabindex="10"></el-input>
				</li> -->
			</ul>
		</div>
		<div class="btn">
			<el-button @click="back" class="back">取消</el-button>
			<el-button @click="onSubmit" class="next" type="primary">{{pageType=='edit'?'保存':'确认'}}</el-button>
		</div>
	</div>
</template>
<script>
	import {
		setThirds,
		getThirds
	} from '@/api/user/applet.js'
	import {
		mapState,
		mapMutations
	} from 'vuex'
	export default {
		data() {
			return {
				formData:{
					thirdId:'',//true	三方ID（URL中，新增时固定为0）
					thirdNo:'',//true	三方编号
					thirdName:'',//true	三方名称
					appId:'',//true	APPID
					appSecret:'',//true	APPSECRET
					token:'',//true	消息校验token
					requestDomain:'',//true	服务器请求域名
					encodingAesKey:'',//true	消息加解密key
					componentPhone:'',//true	第三方联系电话
					thirdRedirectUrl:'',//true	第三方redirect uri
					remark:'',//false	备注
				},
				// warrantUrl:"",			
				pageType:'',
			};
		},
		computed: {
			...mapState({
				thirdsDetail: 'user_thirdsDetail'
			})
		},
		created() {
			
			if (this.$route.query.pageType == 'edit') {
				this.pageType = 'edit';
				// this.warrantUrl = this.$route.query.warrantUrl;
				// console.log(this.warrantUrl);
				this.getDetail()
			} else {
				if (this.thirdsDetail) {
					this.formData = this.thirdsDetail;
				}
			}
		},
		methods: {
			...mapMutations({
				setThirds: 'user_setThirds'
			}),
			// 获取详情
			getDetail(){
				getThirds(this.$route.query.id).then(res=>{
					if(res){
						this.formData = res.resultData
					}
				})
			},
			back() {
				this.$router.go(-1)
			},
			// 表单规则
			roles() {
				let data = JSON.parse(JSON.stringify(this.formData))
				if (!data.thirdNo) {
					this.$message.error('第三方编号必填')
					return false
				}
				if (!data.thirdName) {
					this.$message.error('第三方名称必填')
					return false
				}
				if (!data.appId) {
					this.$message.error('APPID必填')
					return false
				}
				if (!data.appSecret) {
					this.$message.error('密钥必填')
					return false
				}
				if (!data.token) {
					this.$message.error('消息校验token必填')
					return false
				}
				if (!data.requestDomain) {
					this.$message.error('服务器请求域名必填')
					return false
				}
				if (!data.encodingAesKey) {
					this.$message.error('消息加解密key必填')
					return false
				}
				if (!data.componentPhone) {
					this.$message.error('第三方联系电话必填')
					return false
				}
				if ( !/^[1][2,3,4,5,6,7,8,9][0-9]{9}$/.test(data.componentPhone)) {
					this.$message.error('请正确填写手机号码');
					return
				}
				return true
			},
			// 提交
			onSubmit(){
				if (!this.roles()) {
					return
				}
				this.formData.thirdId = this.formData.thirdId?this.formData.thirdId:0
				setThirds(this.formData).then(res=>{
					if(res){
						if(res.resultStatus){
							this.$message.success('保存成功')
						}
						this.setThirds(null)
						if(this.$route.query.pageType == 'edit'){
							this.$router.go(-1)
							// this.$router.push({
							// 	name: "third_party_xp",
							// 	query: {
							// 		id: this.$route.query.id,
							// 		warrantUrl:this.warrantUrl,							
							// 	}
							// })
							return
						}
						this.$router.push({
							name: 'third_party',
						})
					}
				})
			}
		},
		beforeDestroy() {
			if(this.$route.query.pageType != 'edit'){
				this.setThirds(this.formData)
			}
		}
	}
</script>
<style scoped>
@import "../../../../assets/css/desc.css";
@import "../../../../assets/css/editInput.css";
.tab1{
  min-height: auto !important;
}

	.next {
		width: 120px;
		height: 32px;
		padding: 0;
		background: rgba(72, 184, 182, 1);
		border-color: #5AAFAB;
		border-radius: 3px;
		line-height: 10px;
		margin-left: 10px;
		font-size: 12px;
	}

	.back {
		width: 120px;
		line-height: 10px;
		height: 32px;
		padding: 0;
		background: rgba(255, 255, 255, 1);
		border-color: #DCE0E6;
		border: 1px solid rgba(220, 224, 230, 1);
		border-radius: 3px;
		color: #333333;
		font-size: 12px;
	}

	.btn {
		width: 100%;
		margin-top: 50px;
		text-align: center;
		height: 33px;
	}

</style>
